/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import NavMenu from "./NavMenu";
import NavCart from "./NavCart";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterCategories,
  setFilterType,
} from "../../redux/actions/filters";
import NavWishlist from "./NavWishlist";

import { Language } from "./utils";

const Header = () => {
  const dispatch = useDispatch();
  const dictionary = Language.getDictionary();

  const categories = useSelector((state) => state.categories.data);
  const cart = useSelector((state) => state.cart.data);
  const wishlist = useSelector((state) => state.wishlist.data);
  const user = useSelector((state) => state.account.data);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1024;
  const [showMenu, setShowMenu] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const handleMenu = (e) => {
    e?.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleCart = (e) => {
    e?.preventDefault();
    setShowCart(!showCart);
  };

  const handleWishlist = (e) => {
    e?.preventDefault();
    setShowWishlist(!showWishlist);
  };

  const handleLanguage = (e) => {
    e?.preventDefault();
    Language.setLanguage(Language.getLanguage() == "en" ? "ro" : "en");
    window.location.reload();
  };

  const getCartLength = () => {
    let length = 0;

    cart?.length > 0 &&
      cart?.forEach((item) => {
        length += item.amount;
      });
    return length;
  };

  useEffect(() => {
    if (cart && cart.length > 0 && !firstLoad) {
      setShowCart(true);
    }

    setFirstLoad(false);
  }, [cart]);

  return (
    <>
      <div className="bg-dark text-center py-3 text-white">
        <Link to="/products" onClick={() => dispatch(setFilterType("new"))}>
          {
            Language.getLanguage() == "en" ? <>
              Check our <span style={{ color: "#EC0101" }}>New</span> products.
            </> : <>
              Verifica cele mai <span style={{ color: "#EC0101" }}>noi</span> produse.
            </>
          }
        </Link>
      </div>

      <header>
        <div className="d-flex flex-row justify-content-between">
          {!isMobile ? (
            <>
              <Link to="/">
                <img
                  className="logo"
                  src={require("../../assets/logo.png").default}
                  alt="Logo."
                />
              </Link>

              <div className="d-flex flex-row justify-content-between w-100 mx-5">
                <div className="my-auto d-flex flex-row justify-content-center gap-4">
                  <NavCart show={showCart} onClose={handleCart} />
                  <NavWishlist show={showWishlist} onClose={handleWishlist} />

                  <Link
                    className="nav__button"
                    to={
                      JSON.stringify(user?.data) != "{}" ? "/account" : "/login"
                    }
                  >
                    <img
                      className="nav__button__img"
                      src={require("../../assets/account.png").default}
                      alt="My account."
                    />
                  </Link>

                  {/* <img className="nav__button" src={require("../../assets/search.png").default} alt="Search item." /> */}
                </div>

                <div className="nav my-auto">
                  <Link className="nav__option" to="/">
                    {dictionary.header_option_home}
                  </Link>
                  <div className="nav__option">
                    {dictionary.header_option_categories} <div className="arrow__down"></div>
                    <div className="nav__dropdown">
                      {categories
                        .filter((value) => value.type == "primary")
                        .map((category, index) => (
                          <Link
                            key={index}
                            to={`/products`}
                            className="choice"
                            onClick={() =>
                              dispatch(setFilterCategories([category]))
                            }
                          >
                            {category.title}
                          </Link>
                        ))}
                    </div>
                  </div>
                  <div className="nav__option">
                    {dictionary.header_option_anime} <div className="arrow__down"></div>
                    <div className="nav__dropdown">
                      {categories
                        .filter((value) => value.pid == 4)
                        .map((category, index) => (
                          <Link
                            key={index}
                            to={`/products`}
                            className="choice"
                            onClick={() =>
                              dispatch(setFilterCategories([category]))
                            }
                          >
                            {category.title}
                          </Link>
                        ))}
                    </div>
                  </div>
                  <Link className="nav__option" to="/custom-merch">
                    {dictionary.header_option_custom_orders}
                  </Link>
                  <Link className="nav__option" to="/design-gallery">
                    {dictionary.header_option_gallery}
                  </Link>
                </div>

                <div className="my-auto d-flex flex-row justify-content-center gap-4">
                  <div className="nav__button" onClick={handleCart}>
                    <img
                      className="nav__button__img"
                      src={require("../../assets/cart.png").default}
                      alt="My shopping cart."
                    />
                    <div className="nav__button__count">
                      {getCartLength(cart)}
                    </div>
                  </div>
                  <div className="nav__button" onClick={handleWishlist}>
                    <img
                      className="nav__button__img"
                      src={require("../../assets/wishlist.png").default}
                      alt="Wishlist."
                    />
                    <div className="nav__button__count">
                      {Object.keys(wishlist)?.length}
                    </div>
                  </div>
                  <div className="nav__button" onClick={handleLanguage}>
                    <img
                      className="nav__button__img flag"
                      src={Language.getFlags()[Language.getLanguage()]}
                      alt="Language flag."
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <NavMenu show={showMenu} onClose={handleMenu} />
              <NavCart show={showCart} onClose={handleCart} />
              <NavWishlist show={showWishlist} onClose={handleWishlist} />


              <div className="nav__button p-1 w-25" onClick={handleMenu}>
                <img
                  className="nav__button__img"
                  src={require("../../assets/menu.png").default}
                  alt="My menu."
                />
              </div>

              <Link to="/" className="d-flex flex-row justify-content-center align-items-center py-2 gap-1">
                <img
                  className="logo"
                  src={require("../../assets/logo--short.png").default}
                  alt="logo sufy :)"
                />
              </Link>

              <div className="d-flex flex-row justify-content-end gap-2 w-25">
                <div className="nav__button p-1" onClick={handleCart}>
                  <img
                    className="nav__button__img"
                    src={require("../../assets/cart.png").default}
                    alt="My shopping cart."
                  />
                  <div className="nav__button__count">
                    {cart?.length}
                  </div>
                </div>

                <div className="nav__button p-1" onClick={handleWishlist}>
                  <img
                    className="nav__button__img"
                    src={require("../../assets/wishlist.png").default}
                    alt="Wishlist."
                  />
                  <div className="nav__button__count">
                    {Object.keys(wishlist)?.length}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </header >
    </>
  );
};

export default Header;