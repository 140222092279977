/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, Language } from "../common/utils";
import { PageHeader } from '../common';

import { register } from '../../redux/actions/account';

const Index = () => {
    const dispatch = useDispatch();
    const { error } = useSelector(state => state.account);

    const [password, setPassword] = useState("");
    const [cPassword, setConfirmedPassword] = useState("");

    const [userState, setUserState] = useState({
        email: "",
        firstname: "",
        lastname: "",
        phone: ""
    });

    const updateDataField = (field, value) => {
        setUserState({
            ...userState,
            [field]: value
        });
    };

    const dictionary = Language.getDictionary(); // Get the current language dictionary

    return (
        localStorage.getItem("visitor") == "true" ?
            <>
                <Helmet>
                    <title>{dictionary.sign_up}</title> {/* Use dynamic translation for the title */}
                </Helmet>
                <PageHeader page={dictionary.sign_up} /> {/* Use dynamic translation for the page header */}

                <Layout>
                    <h1 className="text-center fs-4 text-black bold landing__title m-auto">
                        {dictionary.sign_up_now} {/* Use dynamic translation for the title */}
                    </h1>
                    <p className="error text-center text-danger mt-4">{error}</p>
                    <div className='input__group w-auto'>
                        <div className='d-flex flex-column flex-wrap justify-content-start gap-5'>
                            <div>
                                <label>{dictionary.first_name}</label> {/* Use dynamic translation for label */}
                                <input
                                    id="fname" name="fname"
                                    type="text"
                                    placeholder={dictionary.enter_first_name}
                                    value={userState.firstname}
                                    onChange={e => updateDataField("firstname", e.target.value)}
                                />
                            </div>

                            <div>
                                <label>{dictionary.last_name}</label> {/* Use dynamic translation for label */}
                                <input
                                    id="lname" name="lname"
                                    type="text"
                                    placeholder={dictionary.enter_last_name}
                                    value={userState.lastname}
                                    onChange={e => updateDataField("lastname", e.target.value)}
                                />
                            </div>
                            <div>
                                <label>{dictionary.email}</label> {/* Use dynamic translation for label */}
                                <input
                                    id="email" name="email"
                                    type="email"
                                    placeholder={dictionary.enter_email}
                                    value={userState.email}
                                    onChange={e => updateDataField("email", e.target.value)}
                                />
                            </div>

                            <div>
                                <label>{dictionary.phone_number}</label> {/* Use dynamic translation for label */}
                                <input
                                    id="phone" name="phone"
                                    type="phone"
                                    placeholder={dictionary.enter_phone_number}
                                    value={userState.phone}
                                    onChange={e => updateDataField("phone", e.target.value)}
                                />
                            </div>
                            <div>
                                <label>{dictionary.password}</label> {/* Use dynamic translation for label */}
                                <input
                                    id="password" name="password"
                                    type="password"
                                    placeholder={dictionary.enter_password}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <label>{dictionary.confirm_password}</label> {/* Use dynamic translation for label */}
                                <input
                                    id="cpassword" name="cpassword"
                                    type="password"
                                    placeholder={dictionary.confirm_password}
                                    value={cPassword}
                                    onChange={e => setConfirmedPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="button m-auto mt-4" onClick={() => {
                            if (password === cPassword) {
                                dispatch(register({
                                    ...userState,
                                    password: password
                                }));
                            }
                            else {
                                alert(dictionary.passwords_do_not_match);
                            }
                        }}>
                            {dictionary.register} {/* Use dynamic translation for button text */}
                        </div>
                    </div>

                    <div className='text-center h6 text-black'>
                        {dictionary.or} {/* Use dynamic translation for "or" text */}
                        <br />
                        {dictionary.press_here_to_login} <Link to="/login" className="text-danger">{dictionary.here}</Link> {/* Use dynamic translation for the link text */}
                    </div>
                </Layout>
            </>
        :
        <Redirect to="/" />
    );
};

export default Index;
