import React from "react";
import { Link } from "react-router-dom";

import { Language } from "./utils";

const PageHeader = (props) => {
    const dictionary = Language.getDictionary();

    return (
        <div className="page-header">
            <div className="subtitle text-gray"><Link to="/" className="text-dark">{dictionary.page_header_home}</Link> / <span className="text-gray">{props.page}</span></div>
        </div>
    );
}

export default PageHeader;