/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Language } from "../common/utils";
import { Helmet } from 'react-helmet';
import { PageHeader } from '../common';

import { editPage } from "../../redux/actions/page";

const Index = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);

    const dictionary = Language.getDictionary(); // Fetch the translation dictionary

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type === "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState(getPageParameter("tos_text"));

    useEffect(() => {
        setText(getPageParameter("tos_text"));
    }, [page]);

    return (
        <>
            <Helmet>
                <title>{dictionary.terms_of_service}</title> {/* Dynamic title based on translation */}
            </Helmet>
            <PageHeader page={dictionary.terms_of_service} /> {/* Dynamic header */}
            <Layout>
                <center>
                    <p className='w-75 mt-2'>
                    {
                        editMode ?
                        <textarea className="text-center w-100" value={text} onChange={e => setText(e.target.value)} />
                        :
                        <div className="whitespace-break-spaces" dangerouslySetInnerHTML={{__html: text}}></div>
                    }

                    {
                        account?.type === "master" &&
                        (
                            editMode ?
                            <div className="d-flex flex-row m-auto">
                                <div className="m-4 user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => {
                                        setEditMode(!editMode);
                                        
                                        dispatch(editPage({
                                            value: text,
                                            name: "tos_text"
                                        }))
                                    }}>{dictionary.done}</span> {/* Dynamic "Done" button */}
                                </div>
                                <div className="m-4 user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => {setEditMode(!editMode); setText(text)}}>{dictionary.close}</span> {/* Dynamic "Close" button */}
                                </div>
                            </div>
                            :
                            <div className="m-4 user-select-none">
                                <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>{dictionary.edit}</span> {/* Dynamic "Edit" button */}
                            </div>
                        )
                    }
                    </p>
                </center>
            </Layout>
        </>
    );
};

export default Index;
