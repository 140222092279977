/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { addToCart, removeFromCart } from "../../redux/actions/cart";

import { Language } from "./utils";

const NavCart = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const dictionary = Language.getDictionary();

  const cart = useSelector((state) => state.cart.data);
  const products = useSelector((state) => state.products);
  const page = useSelector(state => state.page);

  const [totalPrice, setTotalPrice] = useState(0);
  const [types, setTypes] = useState({});

  useEffect(() => {
    if (!products.inLoading && cart?.length > 0) {
      let totalPrice = 0;

      cart?.forEach((item, index) => {
        const product = products?.data?.find((product) => product?.id === item?.id) || {
          id: item?.id,
          price: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.price,
          promo: 0,
          content: item?.content
        }

        if (product !== undefined) {
          if (product.promo > 0) {
            totalPrice += item.amount * Math.floor((product.price - product.price * product.promo / 100) * 100) / 100;
          } else {
            totalPrice += item.amount * product.price;
          }
        }
        else {
          const content = item.content;
          totalPrice += item.amount * types?.[content.category]?.[content.type]?.price;
        }
      })

      setTotalPrice(totalPrice);
    }
  }, [products.inLoading, page.inLoading, cart, types]);

  useEffect(() => {
    window.dataLayer.push({
      'event': 'view_cart', 'ecommerce': {
        'currency': 'RON',
        'value': totalPrice
      }
    });
  }, [show]);


  useEffect(() => {
    if (!page.inLoading) {
      setTypes(getProductTypes());
    }
  }, [page.inLoading]);

  const getPageParameter = (parameter) => {
    for (const param of page?.data) {
      if (param.name === parameter) {
        return param.type == "integer" ? Number(param.value) : param.value;
      }
    }

    return null;
  }

  const getProductTypes = () => {
    return JSON.parse(getPageParameter("product_types"));
  }

  const getImageOfProduct = configurator => {
    let result;

    if (["clothes", "animalut"].includes(configurator.category)) {
      result = configurator.sex != "none" && configurator.type != "none" && configurator.face != "none" && configurator.color != "none" ? require(`../../assets/products/${configurator.category}/${configurator.sex}/${configurator.type}/${configurator.face}-${configurator.color}.png`).default : "https://imgur.com/bCqGeYH.png";
    }
    else if (["pack", "pillow"].includes(configurator.category)) {
      result = configurator.color != "none" ? require(`../../assets/products/${configurator.category}/${configurator.color}.png`).default : "https://imgur.com/bCqGeYH.png";
    }

    return result;
  }

  return show ? (
    <div className="nav__menu">
      <div
        className="nav__menu__overlay"
        id="nav__menu__overlay"
        onClick={onClose}
      ></div>

      <div className="nav__menu__content" id="nav__menu__content">
        <div className="d-flex flex-row justify-content-between p-3 border-bottom py-0 pt-3">
          <h1 className="fs-6 text-uppercase text-black m-0">{dictionary.shopping_cart}</h1>
          <img
            src={require("../../assets/close-o.svg").default}
            alt="Close"
            className="nav__menu__close"
            onClick={onClose}
          />
        </div>
        {cart?.length > 0 ? (
          <>
            {cart?.map((item, key) => {
              let product;

              if (item.content.custom) {
                product = {
                  price: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.price,
                  images: [getImageOfProduct(item?.content), item?.content?.image],
                  promo: 0,
                  title: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.name,
                  content: item?.content,
                  custom: true
                }
              }
              else {
                product = products?.data?.find((product) => product?.id === item?.id);

                if (product === undefined) {
                  return <></>;
                }

                if (product?.stock === 0) {
                  dispatch(removeFromCart(item.id, item.content));
                  return <></>;
                }
              }

              return (
                <div className="cart__product p-3" key={key}>
                  <img
                    className="cart__product__image"
                    src={product?.images[0]}
                    alt={product?.name}
                  />
                  {
                    item.content.custom ?
                      <div className="cart__product__info">
                        <div>
                          Custom {product?.title} {item?.content?.sex} {item?.content?.color}
                        </div>
                        <div>{item.content.size.name}</div>
                        <div>{item.amount} pcs.</div>
                      </div>
                      :
                      <div className="cart__product__info">
                        <div>
                          {product?.title} {item?.content?.color}
                        </div>
                        <div>{item.content.size}</div>
                        <div>{item.amount} pcs.</div>
                      </div>
                  }

                  <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                    <div
                      className="fs-4"
                      onClick={() => {
                        dispatch(removeFromCart(item.id, item.content))

                        window.dataLayer.push({
                          'event': 'remove_from_cart', 'ecommerce': {
                            'items': [{
                              'id': product.id,
                              'name': product.title,
                              'price': product.price,
                            }]
                          }
                        });
                      }}
                    >
                      <span className="icon pointer material-icons-outlined">
                        remove
                      </span>
                    </div>
                    <div>
                      {product?.content?.promo > 0 ? (
                        <>
                          <strike>
                            {(item.amount * product.price).toLocaleString(
                              "ro-RO",
                              { style: "currency", currency: "RON" }
                            )}
                          </strike>
                          <br />
                          {(
                            Math.floor(
                              (item.amount * product.price -
                                (item.amount *
                                  product?.price *
                                  product.content?.promo) /
                                100) *
                              100
                            ) / 100
                          ).toLocaleString("ro-RO", {
                            style: "currency",
                            currency: "RON",
                          })}
                        </>
                      ) : (
                        <>
                          {(item.amount * product.price).toLocaleString(
                            "ro-RO",
                            { style: "currency", currency: "RON" }
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className="fs-4"
                      onClick={() => {
                        dispatch(addToCart(item.id, item.content, 1))
                        
                        window.dataLayer.push({
                          'event': 'add_to_cart', 'ecommerce': {
                            'items': [{
                              'id': product.id,
                              'name': product.title,
                              'price': product.price,
                            }]
                          }
                        });
                      }}
                    >
                      <span className="icon pointer material-icons-outlined">
                        add
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="mx-4">
              Subtotal {(totalPrice).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
            </div>

            <Link to="/checkout" onClick={() => onClose()}>
              <div className="button m-4">{dictionary.checkout}</div>
            </Link>
          </>
        ) : (
          <div className="p-4">
            <center>
              {dictionary.cart_empty}
            </center>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NavCart;
