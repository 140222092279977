/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, Language } from "../common/utils";
import { PageHeader } from '../common';

import { logIn } from '../../redux/actions/account';

const Index = () => {
    const dispatch = useDispatch();
    const { error } = useSelector(state => state.account);

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const dictionary = Language.getDictionary(); // Get the current language dictionary

    return (
        localStorage.getItem("visitor") == "true" ?
        <>
            <Helmet>
                <title>{dictionary.sign_in}</title> {/* Use dynamic translation for the title */}
            </Helmet>
            <PageHeader page={dictionary.sign_in} /> {/* Use dynamic translation for the page header */}

            <Layout>
                <h1 className="text-center fs-4 text-black bold landing__title m-auto">
                    {dictionary.sign_in_now} {/* Use dynamic translation for the title */}
                </h1>
                <p className="error text-center text-danger mt-4">{error}</p>
                <div className='input__group w-auto'>
                    <div className='d-flex flex-column flex-wrap justify-content-start gap-5'>
                        <div>
                            <label>{dictionary.email}</label> {/* Use dynamic translation for labels */}
                            <input 
                                type="text" 
                                placeholder="..." 
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div>
                            <label>{dictionary.password}</label> {/* Use dynamic translation for labels */}
                            <input 
                                type="password" 
                                placeholder="..." 
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    
                    <div className="button m-auto mt-4" onClick={() => dispatch(logIn({email, password}))}>
                        {dictionary.log_in_now} {/* Use dynamic translation for button text */}
                    </div>
                </div>
                <div className='text-center h6 text-black'>
                    {dictionary.or} {/* Use dynamic translation for the separator */}
                    <br />
                    {dictionary.press_here_to_signup} <Link to="/register" className="text-danger">{dictionary.here}</Link> {/* Use dynamic translation for the link text */}
                </div>
            </Layout>
        </>
        :
        <Redirect to="/" />
    );
};

export default Index;
