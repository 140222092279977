export const SET_FILTER_AVAILABILITY = 'SET_FILTER_AVAILABILITY';
export const SET_FILTER_PRICE_RANGE = 'SET_FILTER_PRICE_RANGE';
export const SET_FILTER_CATEGORIES = 'SET_FILTER_CATEGORIES';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';

export function SetFilterAvailability(item) {
    return {
        type: SET_FILTER_AVAILABILITY,
        item: item
    };
}

export function SetFilterPriceRange(item) {
    return {
        type: SET_FILTER_PRICE_RANGE,
        item: item
    };
}

export function SetFilterCategories(item) {
    return {
        type: SET_FILTER_CATEGORIES,
        item: item
    };
}

export function SetFilterType(item) {
    return {
        type: SET_FILTER_TYPE,
        item: item
    };
}


export const setFilterAvailability = (payload) => {
    return dispatch => {
        dispatch(SetFilterAvailability(payload));
    };
}

export const setFilterPriceRange = (payload) => {
    return dispatch => {
        dispatch(SetFilterPriceRange(payload));
    };
}

export const setFilterCategories = (payload) => {
    return dispatch => {
        dispatch(SetFilterCategories(payload));
    };
}

export const setFilterType = (payload) => {
    return dispatch => {
        dispatch(SetFilterType(payload));
    };
}